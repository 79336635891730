export enum Navigation {
  Main = "main",
  AboutMe = "about_me",
  Requests = "requests",
  Consulting = "consulting",
  TermsAndConditions = "terms-and-conditions",
}

export enum Locale {
  ru = "ru",
  uk = "uk",
  en = "en",
}

import {
  Container,
  Grid,
  GridItem,
  ModalCloseButton,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React from "react";
import Form from "./Form";
import Info from "./Info";

const ContactMe = () => {
  const { t } = useTranslation();

  return (
    <ModalContent
      maxWidth={{ base: "100%" }}
      margin={{ base: 0 }}
      minHeight={{
        base: "var(--app-height)",
      }}
      overflowY="auto"
      borderRadius={0}
    >
      <ModalCloseButton
        top={{ base: "2.4rem", lg: "6rem" }}
        right={{ base: "2.4rem", lg: "6rem" }}
        fontSize={{ base: 16, lg: 24 }}
        _focus={{ boxShadow: "none" }}
        zIndex={99}
      />
      <Container
        className="content-visibility"
        maxW="73.6rem"
        p={{ base: "6.4rem 3.2rem 3.2rem", lg: "8rem 1rem" }}
      >
        <Text
          textStyle={{ base: "h40", lg: "h64" }}
          color="text.dark"
          mb={{ base: "3.2rem", lg: "5.6rem" }}
        >
          {t("modal.title")}
        </Text>
        <Grid gridTemplateColumns={{ base: "1fr", lg: "35.2rem 1fr" }}>
          <GridItem order={{ base: 1, lg: 0 }}>
            <Form />
          </GridItem>
          <GridItem
            backgroundImage={{ base: "none", lg: "url('/img/reviews.svg')" }}
            bgRepeat="no-repeat"
            bgPosition="bottom"
            justifySelf={{ base: "unset", lg: "end" }}
          >
            <Info />
          </GridItem>
        </Grid>
      </Container>
    </ModalContent>
  );
};

export default ContactMe;

import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { breakpoints } from "./breakpoints";
import { textStyles } from "./textStyles";
import Button from "./components/Button";
import Tag from "./components/Tag";
import Input from "./components/Input";
import Textarea from "./components/TextArea";

const theme = extendTheme({
  components: {
    Button,
    Tag,
    Input,
    Textarea,
  },
  colors,
  textStyles,
  fonts: {
    heading: "Cormorant, Carolina",
    body: "Futura, Carolina",
  },
  breakpoints,
  styles: {
    global: {
      root: {
        "--app-height": "100vh",
      },
      html: { fontSize: "10px", scrollBehavior: "smooth" },
      a: {
        textDecoration: "none",
      },
      "*": {
        margin: 0,
        padding: 0,
      },

      ".chakra-modal__content-container": {
        height: "var(--app-height) !important",
      },

      ".content-visibility": {
        contentVisibility: "auto",
        containIntrinsicSize: "0 500px",
      },

      strong: {
        fontWeight: 500,
      },

      "h1, h2, h3, h4, h5, h6, span, p": {
        hyphens: "auto",
      },

      "#__next": {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",

        "@media (max-width: 768px)": {
          minHeight: "100dvh",
        },
      },
    },
  },
});

export interface CustomTheme {
  colors: typeof colors;
  textStyles: typeof textStyles;
}

export default theme;

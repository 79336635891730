import { useEffect } from "react";

interface Props {
  block: boolean;
}
export function useLockBodyScroll({ block }: Props) {
  useEffect(() => {
    document.body.style.overflow = block ? "hidden" : "visible";
  }, [block]); // Empty array ensures effect is only run on mount and unmount
}

import type { AppProps } from "next/app";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import theme from "theme";
import { appWithTranslation } from "next-i18next";
import Fonts from "theme/fonts";
import { ContactMeModalProvider } from "hooks/useContactMeModalCtx";
import NextNProgress from "nextjs-progressbar";
import { colors } from "theme/colors";
import "flickity/dist/flickity.min.css";
import "react-image-lightbox/style.css";
import "react-phone-input-2/lib/style.css";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Script from "next/script";
import { useEffect } from "react";
import { lazyLoadBgImage } from "utils";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useRouter } from "next/router";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <Flex
      role="alert"
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary} aria-label="Reload the page">
        Try again
      </button>
    </Flex>
  );
}

if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  throw new Error("Missing NEXT_PUBLIC_POSTHOG_KEY env variable");
}

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(lazyLoadBgImage, []);

  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <ChakraProvider theme={theme}>
        <ContactMeModalProvider>
          <Script
            strategy="afterInteractive"
            src="https://secure.wayforpay.com/server/pay-widget.js"
            type="text/javascript"
            id="widget-wfp-script"
          />
          <Fonts />
          <NextNProgress color={colors.main.green} />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </ContactMeModalProvider>
      </ChakraProvider>
    </PostHogProvider>
  );
}

export default appWithTranslation(MyApp);

export const colors = {
  main: {
    green: "#4F6231",
    pink: "#ff6489",
  },
  text: {
    dark: "#221D1C",
    grey: "#353131",
    light_grey: "#6B6666",
    light_green: "#B3C1AE",
  },
  bg: {
    beige: "#F4F0ED",
  },
};

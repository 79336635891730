const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "700",
    border: "1px solid",
    textTransform: "uppercase",
    borderRadius: 0,
    fontFamily: "Cormorant",
    path: {
      transitionDuration: "var(--chakra-transition-duration-normal)",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      borderColor: "main.green",
      fontSize: "1.6rem",
      color: "main.green",
      height: "4.8rem",
      padding: "1.3rem 1.45rem",
      _hover: {
        backgroundColor: "main.green",
        color: "white",
        borderColor: "main.green",
        path: {
          fill: "white",
        },
      },
    },
    solid: {
      background: "main.green",
      color: "white",
      fontSize: "1.6rem",
      height: "5.6rem",
      padding: "1.7rem 2rem",
      _hover: {
        backgroundColor: "transparent",
        color: "main.green",
        borderColor: "main.green",
        path: {
          fill: "main.green",
        },
      },
    },
  },
};

export default Button;

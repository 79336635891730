import {
  Box,
  Flex,
  HStack,
  Text,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import React, { HTMLProps } from "react";
import TelegramIcon from "@img/telegram.svg";
import WhatsAppIcon from "@img/whatsapp.svg";
import ViberIcon from "@img/viber.svg";
import { ControllerRenderProps } from "react-hook-form";
interface Props extends ControllerRenderProps {}

const SocialRadioButtons = ({ name, onChange, value }: Props) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange,
  });

  const group = getRootProps();

  return (
    <Flex {...group} gap="0.8rem" flexWrap={{ base: "wrap", xsm: "nowrap" }}>
      <RadioButton getRadioProps={getRadioProps} type="telegram" />
      <RadioButton getRadioProps={getRadioProps} type="viber" />
      <RadioButton getRadioProps={getRadioProps} type="whatsapp" />
    </Flex>
  );
};

interface RadioButtonProps {
  type: "telegram" | "viber" | "whatsapp";
  getRadioProps: any;
}

const basicStyles = {
  cursor: "pointer",
  borderWidth: "1px",
  borderRadius: 0,
  borderColor: "transparent",
  boxShadow: "none",
  px: { base: "0.8rem", lg: "1.45rem" },
  py: "0.8rem",
};

const RadioButton = ({ getRadioProps, type }: RadioButtonProps) => {
  const radio = getRadioProps({ value: type });

  const { getInputProps, getCheckboxProps } = useRadio(radio);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const getComponent = (checkbox: Omit<HTMLProps<any>, any>) => {
    switch (type) {
      case "telegram":
        return (
          <Flex
            {...checkbox}
            {...basicStyles}
            color="#4DA2DB"
            bg="#EBF4FB"
            _checked={{
              borderColor: "#4DA2DB",
            }}
            aria-label="Messanger radio"
          >
            <TelegramIcon />
            <Text ml="2px" textStyle="b16r">
              Telegram
            </Text>
          </Flex>
        );
      case "viber":
        return (
          <Flex
            {...checkbox}
            {...basicStyles}
            color="#4E47C3"
            bg="#F0EFFF"
            _checked={{
              borderColor: "#4E47C3",
            }}
            aria-label="Messanger radio"
          >
            <ViberIcon />
            <Text ml="2px" textStyle="b16r">
              Viber
            </Text>
          </Flex>
        );
      case "whatsapp":
        return (
          <Flex
            {...checkbox}
            {...basicStyles}
            color="#58BF4B"
            bg="#EFF9ED"
            _checked={{
              borderColor: "#58BF4B",
            }}
            aria-label="Messanger radio"
          >
            <WhatsAppIcon />
            <Text ml="2px" textStyle="b16r">
              WhatsApp
            </Text>
          </Flex>
        );
    }
  };

  return (
    <Box as="label" flexGrow={1}>
      <input {...input} />
      {getComponent(checkbox)}
    </Box>
  );
};

export default SocialRadioButtons;

import { Box, Flex, IconButton, RenderProps, Text } from "@chakra-ui/react";
import React from "react";
import CheckIcon from "@img/checkmark.svg";
import CloseIcon from "@img/close.svg";

interface Props extends RenderProps {
  status: "success";
  title: string;
}

const Toast = ({ status, title, id, onClose }: Props) => {
  if (status === "success") {
    return (
      <Flex
        alignItems="center"
        // bg="green.200"
        padding="1.6rem"
        position="relative"
        bg="main.green"
      >
        <Box
          mr="1.6rem"
          w="2.4rem"
          h="2.4rem"
          color="white"
          sx={{
            svg: {
              width: "100%",
              height: "100%",
              path: {
                fill: "white",
              },
            },
          }}
        >
          <CheckIcon />
        </Box>
        <Text textStyle="b16" color="white">
          {title}
        </Text>
        <Box
          ml="1.6rem"
          cursor="pointer"
          onClick={onClose}
          sx={{
            rect: {
              fill: "white",
            },
          }}
        >
          <CloseIcon />
        </Box>
      </Flex>
    );
  }
  return null;
};

export default Toast;

export const breakpoints = {
  xsm: "370px",
  sm: "480px",
  md: "768px",
  'moreThan769': "769px",
  lg: "960px",
  xl: "1060px",
  "2xl": "1300px",
  "4xl": "1536px",
};

import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { setAppHeight } from "utils";

export function useResize() {
  const debouncedSetHeight = useDebouncedCallback(setAppHeight, 300);

  useEffect(() => {
    setTimeout(setAppHeight, 300);
    setTimeout(setAppHeight, 2000);

    window.addEventListener("resize", debouncedSetHeight);
    () => {
      window.removeEventListener("resize", debouncedSetHeight);
    };
  }, [debouncedSetHeight]);
}

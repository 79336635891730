import { tagAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/react";

const baseStyleContainer: SystemStyleObject = {
  fontWeight: "medium",
  lineHeight: 1.2,
  outline: 0,
  _focus: {
    boxShadow: "outline",
  },
};

const baseStyle: PartsStyleObject<typeof parts> = {
  container: baseStyleContainer,
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    container: {
      padding: "1px 4px",
      minH: "1.6rem",
      fontSize: "1.2rem",
      borderRadius: "1px",
    },
  },
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  solid: ({ colorScheme }) => {
    return {
      container: {
        bg: "transparent",
        color: "main.green",
        border: "1px solid",
      },
    };
  },
};

const defaultProps = {
  size: "md",
  variant: "solid",
  colorScheme: "green",
};

const Tag = {
  parts: parts.keys,
  variants,
  baseStyle,
  sizes,
  defaultProps,
};

export default Tag;

import { Text } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { Wrapper } from "./styled";

interface Props extends Omit<PhoneInputProps, "onChange"> {
  size: "sm" | "md";
  isInvalid: boolean;
  error?: string;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  value: string;
}

const MyPhoneInput = ({
  placeholder,
  size,
  error,
  isInvalid,
  onChange,
  onBlur,
  value,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const inputWidth = inputRef?.current?.clientWidth;

  const handleChange: PhoneInputProps["onChange"] = (
    value,
    data,
    event,
    formattedValue
  ) => {
    onChange(formattedValue);
  };

  const handleBlur = () => {
    onBlur();
    setIsFocused(false);
  };

  return (
    <Wrapper
      w="100%"
      $size={size}
      $isInvalid={isInvalid}
      $isFocused={isFocused}
      $inputWidth={inputWidth}
      aria-label="Phone input"
    >
      <PhoneInput
        placeholder={placeholder}
        containerClass="phone-input-container"
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        inputProps={{ ref: inputRef }}
        onChange={handleChange}
        value={value}
        showDropdown={false}

      />
      {error && (
        <Text color="main.pink" textStyle="b12">
          {error}
        </Text>
      )}
    </Wrapper>
  );
};

export default MyPhoneInput;

import { useTheme } from "@chakra-ui/react";
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useMemo } from "react";
import { GroupBase, StylesConfig } from "react-select";
import { CustomTheme } from "theme";

interface Props {
  isSimple?: boolean;
  error?: string;
  hideBorder?: boolean;
}
export const useSelectStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  isSimple,
  error,
  hideBorder,
}: Props) => {
  const { colors, textStyles } = useTheme<CustomTheme>();
  const lessThan768 = useMediaQuery("(max-width: 767px)");

  const customStyles: StylesConfig<Option, IsMulti, Group> = useMemo(() => {
    return {
      control: (provided, { isFocused }) => {
        let borderColor = colors.text.dark;
        if (isFocused) {
          borderColor = "#768D50";
        }
        if (error) {
          borderColor = colors.main.pink;
        }

        let border = `1px solid ${borderColor}`;
        if (hideBorder) {
          border = "";
        }

        return {
          height: lessThan768 ? "4.8rem" : "5.6rem",
          border,
          display: "flex",
          alignItems: "center",
          transition: "0.3s",
          padding: lessThan768 ? "1.6rem" : "0 1.6rem 0 1.6rem",
          cursor: "pointer",
        };
      },
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: 0,
        paddingRight: isSimple ? 0 : 4,
      }),
      placeholder: (provided) => ({
        ...provided,
        ...textStyles.b16r,
        color: colors.text.light_grey,
      }),
      input: (provided) => ({
        ...provided,
        ...textStyles.b16r,
        color: colors.text.dark,
      }),
      indicatorSeparator: () => ({}),
      indicatorsContainer: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "> div": {
          padding: 0,
          color: colors.text.dark,
          "&:hover": {
            color: colors.text.dark,
          },
        },
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: "0.4rem",
        boxShadow: "none",
        backgroundColor: "white",
        border: `1px solid #768D50`,
        overflow: "hidden",
        borderRadius: 0,
      }),
      menuList: (provided) => ({
        ...provided,
        padding: "0",
      }),
      option: (provided, { isSelected, isFocused, isDisabled }) => {
        return {
          ...textStyles.b16r,
          cursor: isDisabled ? "not-allowed" : "pointer",
          color: isSelected ? colors.main.green : colors.text.dark,
          padding: lessThan768 ? "0.8rem 1.6rem" : "0.8rem 1.6rem",
          "&:first-child": {
            paddingTop: "1.6rem",
          },
          "&:last-child": {
            paddingBottom: "1.6rem",
          },
          "&:hover": {
            color: colors.main.green,
          },
          position: "relative",
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: isSelected || isFocused ? colors.main.green : "",
            opacity: isSelected ? 0.5 : isFocused ? 0.2 : 1,
          },
        };
      },
      singleValue: (provided) => ({
        ...provided,
        ...textStyles.b16r,
        color: colors.text.dark,
        marginLeft: 0,
        marginRight: 0,
      }),
      noOptionsMessage: () => ({
        ...textStyles.b16r,
        color: colors.text.grey,
        padding: "1.6rem",
        hyphens: "auto",
      }),
    };
  }, [colors, textStyles, error, isSimple, hideBorder, lessThan768]);

  return customStyles;
};

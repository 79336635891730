import { createClient, groq } from "next-sanity";
import imageUrlBuilder from "@sanity/image-url";
import { RefObject } from "react";
import { Navigation } from "./enum";

export const sanityConfig = {
  projectId: "uk3pwwpy",
  dataset: "production",
  apiVersion: "2022-05-03",
  useCdn: process.env.NODE_ENV === "production",
};

export const HEADER_ID = "header";

export const LANGUAGES = [
  {
    label: "RU",
    locale: "ru",
  },
  {
    label: "UKR",
    locale: "uk",
  },
  {
    label: "ENG",
    locale: "en",
  },
];

export const sanityClient = createClient(sanityConfig);

const builder = imageUrlBuilder(sanityClient);

export function urlFor(source: string) {
  return builder.image(source);
}

export const getRefElement = <T>(
  element?: RefObject<Element> | T
): Element | T | undefined | null => {
  if (element && "current" in element) {
    return element.current;
  }

  return element;
};

export const isSSR: boolean = !(typeof window !== "undefined");

export const LINKS = [
  { t: "footer.nav.main", url: "/" },
  { t: "footer.nav.about_me", url: "/#" + Navigation.AboutMe },
  { t: "footer.nav.consultation", url: "/#" + Navigation.Consulting },
  { t: "footer.nav.blog", url: "/posts" },
];

export const getAllPosts = `
*[_type == "post" && title[$locale] match $query] | order(_createdAt desc) {
  _id,
  'mainImage': mainImage.asset -> url,
  'slug': slug.current,
  title,
  _createdAt,
  categories[]->{_id, title}
}`;

export const getPostByCategory = `
  *[_type == "post" && $slug in categories[]-> slug.current && title[$locale] match $query] | order(_createdAt desc) {
    _id,
    'mainImage': mainImage.asset -> url,
    title,
    'slug': slug.current,
    _createdAt,
    categories[]->{_id, title}
  }
`;

export const getCategories = `
*[_type == "category"] {
  _id,
  title,
  'slug' : slug.current
}`;

export const getPostsFull = groq`
*[_type == "post"] {
  ...,
  'slug': slug.current
  categories[]->{title},
  "ingredients": ingredients[]{
    amount,
    ingredient -> {
      image,
      title
    },
    measure -> {
      title,
      plural
    }
  }
}`;

export const filterPostByCategory = groq`
  *[_type == "post" && $id in categories[]-> _id && title[$locale] match $query] {
    'mainImage': mainImage.asset -> url,
    'slug': slug.current,
    title,
    _createdAt,
    categories[]->{_id, title}
}`;

interface PostTelegramMessageProps {
  messenger: string;
  name: string;
  phone: string;
  message?: string;
  service?: string;
}
export const postTelegramMessage = (input: PostTelegramMessageProps) => {
  return fetch("/api/sendMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  });
};

export const capitalize = (str?: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

export const setAppHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty(
    "--app-height",
    window.innerWidth <= 768 ? "100dvh" : `100vh`
  );
};

export const lazyLoadBgImage = () => {
  const lazyBackgrounds = [].slice.call(
    document.querySelectorAll(".lazy-background")
  );

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
};

import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CustomTheme } from "theme";
import { transientOptions } from "utils/transientOptions";

export const Wrapper = styled(Box, transientOptions)<{
  $size: "sm" | "md";
  $isInvalid: boolean;
  $isFocused: boolean;
  $inputWidth?: number;
}>`
  .phone-input-container {
    font-size: 1.6rem;
    font-family: var(--chakra-fonts-body);

    input {
      height: ${({ $size }) => ($size === "md" ? "5.6rem" : "4.8rem")};
      font-size: 1.6rem;
      width: 100%;
      padding-left: 6.8rem;
      border-radius: 0;
      border-color: ${({ theme, $isInvalid, $isFocused }) => {
        const colors: CustomTheme["colors"] = (theme as any).colors;
        if ($isInvalid) {
          return colors.main.pink;
        }
        if ($isFocused) {
          return colors.main.green;
        }
        return colors.text.dark;
      }};
    }

    .flag-dropdown {
      background: none;
      border-radius: 0;
      border-color: ${({ theme, $isInvalid, $isFocused }) => {
        const colors: CustomTheme["colors"] = (theme as any).colors;
        if ($isInvalid) {
          return colors.main.pink;
        }
        if ($isFocused) {
          return colors.main.green;
        }
        return colors.text.dark;
      }};
    }

    .selected-flag {
      padding-left: 1.6rem;
      width: 5.6rem;

      .arrow {
        left: 2.4rem;
      }
    }

    .country-list {
      width: ${({ $inputWidth }) => $inputWidth + "px"};
    }
  }
`;

import { Box } from '@chakra-ui/react';
import {
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  components,
  OptionProps,
} from 'react-select';
import { ArrowIcon } from './styled';

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
  ) => {
  const isOpen = props.selectProps.menuIsOpen;
  // @ts-ignore
  const { hideArrow } = props.selectProps;
  if (hideArrow) {
    return null;
  }
  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon $isOpen={isOpen} />
    </components.DropdownIndicator>
  );
};

export const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
    children,
    ...props
  }: ControlProps<Option, IsMulti, Group>) => {
  return (
    <components.Control {...props}>
      {/* @ts-ignore */}
      {props.selectProps.leftIcon && (
        <Box mr="0.8rem">
          {/* @ts-ignore */}
          {props.selectProps.leftIcon}
        </Box>
      )}
      {children}
    </components.Control>
  );
};

export const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
    children,
    ...rest
  }: OptionProps<Option, IsMulti, Group>) => {
  return <components.Option {...rest}>{children}</components.Option>;
};

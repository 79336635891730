import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* futura */
      @font-face {
        font-family: 'Futura';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/FuturaPT-Book.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Futura';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/FuturaPT-Medium.ttf') format('truetype');
        font-display: swap;
      }
     
      @font-face {
        font-family: 'Carolina';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Carolina.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Cormorant';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/Cormorant-Bold.ttf') format('truetype');
        font-display: swap;
      }
      @font-face {
        font-family: 'Cormorant';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/Cormorant-SemiBold.ttf') format('truetype');
        font-display: swap;
      }
      @font-face {
        font-family: 'Cormorant';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/Cormorant-Medium.ttf') format('truetype');
        font-display: swap;
      }
      @font-face {
        font-family: 'Cormorant';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Cormorant-Regular.ttf') format('truetype');
        font-display: swap;
      }
      `}
  />
);

export default Fonts;

import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import DownIcon from "@img/down.svg";
import { transientOptions } from "utils/transientOptions";

export const ArrowIcon = styled(DownIcon, transientOptions)<{
  $isOpen: boolean;
}>`
  transition: 0.3s;
  transform: ${({ $isOpen }) => ($isOpen ? "rotateZ(180deg)" : "rotateZ(0)")};
`;

export const textStyles = {
  h64: {
    fontFamily: "Cormorant",
    fontWeight: 700,
    fontSize: "6.4rem",
    lineHeight: "7.2rem",
  },
  h88: {
    fontFamily: "Cormorant",
    fontWeight: 400,
    fontSize: "8.8rem",
    lineHeight: "7.2rem",
  },
  h40: {
    fontFamily: "Cormorant",
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: "4.8rem",
  },
  h32: {
    fontFamily: "Cormorant",
    fontWeight: 700,
    fontSize: "3.2rem",
    lineHeight: "4rem",
  },
  h24: {
    fontFamily: "Cormorant",
    fontWeight: 600,
    fontSize: "2.4rem",
    lineHeight: "3.2rem",
  },

  b20: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "2.3rem",
  },
  b18: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
  },
  b16: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
  },
  b16r: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
  },
  b14: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: "1.8rem",
  },
  b12: {
    fontFamily: "Futura",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
  },
};

import { Modal, useDisclosure } from "@chakra-ui/react";
import React, { createContext, useContext, useMemo } from "react";
import ContactMe from "components/Modals/ContactMe";
import { useLockBodyScroll } from "./useLockBodyScroll";
import { useResize } from "./useResize";

const ContactMeModalContext = createContext(
  {} as { dialog: JSX.Element; onOpen: () => void; onClose: () => void }
);

interface Props {
  children: React.ReactNode;
}
export const ContactMeModalProvider = ({ children }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useLockBodyScroll({ block: isOpen });

  useResize();

  const dialog = useMemo(() => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ContactMe />
      </Modal>
    );
  }, [isOpen, onClose]);

  const value = useMemo(
    () => ({ dialog, onClose, onOpen }),
    [dialog, onClose, onOpen]
  );

  return (
    <ContactMeModalContext.Provider value={value}>
      {children}
    </ContactMeModalContext.Provider>
  );
};

export const useContactMeModal = () => {
  const context = useContext(ContactMeModalContext);

  if (context === undefined) {
    throw new Error(
      "useContactMeModal must be used within ContactMeModalContext.Provider"
    );
  }
  return context;
};

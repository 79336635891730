import React, { useMemo } from "react";
import ReactSelect, { GroupBase } from "react-select";
import { Box, Text } from "@chakra-ui/react";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { DropdownIndicator, Control, Option } from "./components";
import { useSelectStyles } from "./useStyles";

interface Props<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends StateManagerProps<Option, IsMulti, Group> {
  label?: string;
  error?: string;
  isSimple?: boolean;
  leftIcon?: React.ReactNode;
  hideArrow?: boolean;
  hideBorder?: boolean;
  wrapperClassname?: string;
}

export const SelectBase = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  error,
  isSimple,
  hideBorder,
  ...rest
}: Props<Option, IsMulti, Group>) => {
  const styles = useSelectStyles<Option, IsMulti, Group>({
    error,
    isSimple,
    hideBorder,
  });

  const components = useMemo(() => {
    return { DropdownIndicator, Control, Option };
  }, []);

  return (
    <ReactSelect
      {...rest}
      instanceId="unique"
      styles={styles}
      components={components}
    />
  );
};

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  error,
  wrapperClassname,
  ...rest
}: Props<Option, IsMulti, Group>) => {
  return (
    <Box
      width="100%"
      className={wrapperClassname}
      aria-label="dropdown"
      sx={{
        "#react-select-unique-listbox": {
          zIndex: 10,
        },
      }}
    >
      {label && (
        <Text mb="1" textStyle="s12">
          {label}
        </Text>
      )}
      <SelectBase {...rest} />
      {error && (
        <Text mt="2px" textStyle="s11r" color="main.pink">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default Select;

import React from "react";
import { Box, Button, Input, Text, Textarea, VStack } from "@chakra-ui/react";
import SocialRadioButtons from "./Radio";
import { useForm, Controller } from "react-hook-form";
import { Select } from "components/Select";
import { postTelegramMessage } from "utils";
import { Option } from "utils/types";
import { useTranslation } from "next-i18next";
import { useToast } from "@chakra-ui/react";
import Toast from "components/Toast";
import PhoneInput from "components/PhoneInput";
import { useMediaQuery } from "hooks/useMediaQuery";

export interface FormValues {
  messenger: "telegram" | "viber" | "whatsapp";
  phone: string;
  name: string;
  message?: string;
  service?: Option;
}

const Form = () => {
  const lessThan768 = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation("common");
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      messenger: "telegram",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await postTelegramMessage({ ...data, service: data.service?.value });
    toast({
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
      render: (props) => (
        <Toast
          status="success"
          title={t("modal.successful_request")}
          {...props}
        />
      ),
    });
  });

  const options = t("modal.services", { returnObjects: true }) as any;

  return (
    <form onSubmit={onSubmit}>
      <Text textStyle="b16r" color="text.grey" mb="0.8rem">
        {t("modal.subtitle")}
      </Text>
      <Box mb={{ base: "0.8rem", md: "1.6rem" }}>
        <Controller
          name="messenger"
          control={control}
          render={({ field }) => <SocialRadioButtons {...field} />}
        />
      </Box>
      <VStack gap={{ base: "0.8rem", md: "1.6rem" }}>
        {/* <Box w="100%">
          <Input
            isInvalid={!!errors.phone}
            aria-label="Phone input"
            placeholder={t("modal.phone_number.placeholder") + "*"}
            size={lessThan768 ? "sm" : "md"}
            {...register("phone", { required: t("validation.required") || "" })}
          />
          {errors.phone && (
            <Text color="main.pink" textStyle="b12">
              {errors.phone.message}
            </Text>
          )}
        </Box> */}
        <Controller
          name="phone"
          rules={{
            required: t("validation.required") || "",
          }}
          control={control}
          render={({ field }) => (
            <PhoneInput
              isInvalid={!!errors.phone}
              size={lessThan768 ? "sm" : "md"}
              placeholder={t("modal.phone_number.placeholder") + "*"}
              error={errors.phone?.message}
              {...field}
            />
          )}
        />
        <Box w="100%">
          <Input
            isInvalid={!!errors.name}
            aria-label="Name input"
            placeholder={t("modal.name.placeholder") + "*"}
            size={lessThan768 ? "sm" : "md"}
            {...register("name", { required: t("validation.required") || "" })}
          />
          {errors.name && (
            <Text color="main.pink" textStyle="b12">
              {errors.name.message}
            </Text>
          )}
        </Box>
        <Textarea
          placeholder={t("modal.message.placeholder") || ""}
          aria-label="Message textarea"
          size={lessThan768 ? "sm" : "md"}
          {...register("message")}
        />
        <Controller
          name="service"
          control={control}
          render={({ field }) => (
            <Select
              placeholder={t("modal.service.placeholder")}
              options={options}
              isSearchable={false}
              menuPlacement="top"
              {...field}
            />
          )}
        />
      </VStack>
      <Button
        w="100%"
        variant="solid"
        type="submit"
        mt="2.4rem"
        disabled={isSubmitting}
        aria-label="Submit modal"
      >
        {t("welcome.contact_me")}
      </Button>
    </form>
  );
};

export default Form;

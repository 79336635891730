import { Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React from "react";

const Info = () => {
  const { t } = useTranslation();

  return (
    <VStack
      gap="2.4rem"
      alignItems="flex-start"
      pb={{ base: "3.2rem", lg: 0 }}
      mb={{ base: "3.2rem", lg: 0 }}
      borderBottom={{ base: "1px solid #EBE8E7", lg: "none" }}
    >
      <InfoItem title="WhatsApp, Telegram, Viber" value="+380637463078" />
      <InfoItem
        title={t("modal.for_calls")}
        value="+34605061338"
        href="tel:+34605061338"
      />
      <InfoItem
        title={t("modal.email")}
        value="romanovskaya.daria.s@gmail.com"
        href="mailto:romanovskaya.daria.s@gmail.com"
      />
    </VStack>
  );
};

interface InfoItemProps {
  title: string;
  value: string;
  href?: string;
}
const InfoItem = ({ title, value, href }: InfoItemProps) => {
  return (
    <VStack alignItems="flex-start">
      <Text textStyle="b16" color="text.grey">
        {title}
      </Text>
      <Text
        as="a"
        href={href}
        textStyle={{ base: "b18", lg: "b20" }}
        color="text.dark"
      >
        {value}
      </Text>
    </VStack>
  );
};

export default Info;
